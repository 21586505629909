/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SponsoredReward,
    SponsoredRewardFromJSON,
    SponsoredRewardFromJSONTyped,
    SponsoredRewardToJSON,
    SponsoredRewardEmail,
    SponsoredRewardEmailFromJSON,
    SponsoredRewardEmailFromJSONTyped,
    SponsoredRewardEmailToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateSponsoredCompetitionBody
 */
export interface CreateSponsoredCompetitionBody {
    /**
     * 
     * @type {string}
     * @memberof CreateSponsoredCompetitionBody
     */
    sponsorBrandName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSponsoredCompetitionBody
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSponsoredCompetitionBody
     */
    promotionImage: string;
    /**
     * 
     * @type {Array<SponsoredReward>}
     * @memberof CreateSponsoredCompetitionBody
     */
    rewards: Array<SponsoredReward>;
    /**
     * 
     * @type {number}
     * @memberof CreateSponsoredCompetitionBody
     */
    runningMonth: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSponsoredCompetitionBody
     */
    runningYear: number;
    /**
     * 
     * @type {SponsoredRewardEmail}
     * @memberof CreateSponsoredCompetitionBody
     */
    email?: SponsoredRewardEmail;
    /**
     * URL to the brand\'s website (to display on competition page)
     * @type {string}
     * @memberof CreateSponsoredCompetitionBody
     */
    brandUrl: string;
}

export function CreateSponsoredCompetitionBodyFromJSON(json: any): CreateSponsoredCompetitionBody {
    return CreateSponsoredCompetitionBodyFromJSONTyped(json, false);
}

export function CreateSponsoredCompetitionBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSponsoredCompetitionBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sponsorBrandName': json['sponsorBrandName'],
        'description': json['description'],
        'promotionImage': json['promotionImage'],
        'rewards': ((json['rewards'] as Array<any>).map(SponsoredRewardFromJSON)),
        'runningMonth': json['runningMonth'],
        'runningYear': json['runningYear'],
        'email': !exists(json, 'email') ? undefined : SponsoredRewardEmailFromJSON(json['email']),
        'brandUrl': json['brandUrl'],
    };
}

export function CreateSponsoredCompetitionBodyToJSON(value?: CreateSponsoredCompetitionBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sponsorBrandName': value.sponsorBrandName,
        'description': value.description,
        'promotionImage': value.promotionImage,
        'rewards': ((value.rewards as Array<any>).map(SponsoredRewardToJSON)),
        'runningMonth': value.runningMonth,
        'runningYear': value.runningYear,
        'email': SponsoredRewardEmailToJSON(value.email),
        'brandUrl': value.brandUrl,
    };
}



/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddGuildBody,
    AddGuildBodyFromJSON,
    AddGuildBodyToJSON,
    AddGuildQuestBody,
    AddGuildQuestBodyFromJSON,
    AddGuildQuestBodyToJSON,
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    ClaimGuildQuestResponse,
    ClaimGuildQuestResponseFromJSON,
    ClaimGuildQuestResponseToJSON,
    CreateSponsoredCompetitionBody,
    CreateSponsoredCompetitionBodyFromJSON,
    CreateSponsoredCompetitionBodyToJSON,
    GetCurrentGuildTopMembersResponse,
    GetCurrentGuildTopMembersResponseFromJSON,
    GetCurrentGuildTopMembersResponseToJSON,
    GetGuildOwnershipResponse,
    GetGuildOwnershipResponseFromJSON,
    GetGuildOwnershipResponseToJSON,
    GetGuildProgressResponse,
    GetGuildProgressResponseFromJSON,
    GetGuildProgressResponseToJSON,
    GetGuildQuestsResponse,
    GetGuildQuestsResponseFromJSON,
    GetGuildQuestsResponseToJSON,
    GetGuildsResponse,
    GetGuildsResponseFromJSON,
    GetGuildsResponseToJSON,
    GetGuildsSort,
    GetGuildsSortFromJSON,
    GetGuildsSortToJSON,
    GetQuestActiveStatus,
    GetQuestActiveStatusFromJSON,
    GetQuestActiveStatusToJSON,
    GetSponsoredGuildCompetitionsResponse,
    GetSponsoredGuildCompetitionsResponseFromJSON,
    GetSponsoredGuildCompetitionsResponseToJSON,
    GetTopGuildLeadersLeaderboardResponse,
    GetTopGuildLeadersLeaderboardResponseFromJSON,
    GetTopGuildLeadersLeaderboardResponseToJSON,
    GetTopGuildsLeaderboardResponse,
    GetTopGuildsLeaderboardResponseFromJSON,
    GetTopGuildsLeaderboardResponseToJSON,
    Guild,
    GuildFromJSON,
    GuildToJSON,
    NftMintTransaction,
    NftMintTransactionFromJSON,
    NftMintTransactionToJSON,
    OWRewardType,
    OWRewardTypeFromJSON,
    OWRewardTypeToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
    UpdateSponsoredCompetitionBody,
    UpdateSponsoredCompetitionBodyFromJSON,
    UpdateSponsoredCompetitionBodyToJSON,
} from '../models';

export interface AddGuildRequest {
    addGuildBody?: AddGuildBody;
}

export interface ClaimGuildQuestRequest {
    id: string;
    guildId: string;
    gameId: string;
    claimedTarget?: number;
    rewardType?: OWRewardType;
}

export interface CreateGuildQuestRequest {
    addGuildQuestBody?: AddGuildQuestBody;
}

export interface CreateSponsoredGuildCompetitionRequest {
    createSponsoredCompetitionBody?: CreateSponsoredCompetitionBody;
}

export interface GetCurrentGuildTopMembersRequest {
    leaderboardTimeParam: string;
}

export interface GetGuildRequest {
    id: string;
}

export interface GetGuildProgressRequest {
    id: string;
    freshCopy?: boolean;
}

export interface GetGuildQuestsRequest {
    game?: string;
    guild?: string;
    search?: string;
    isActive?: GetQuestActiveStatus;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetGuildsRequest {
    gameIds?: Array<string>;
    search?: string;
    sort?: GetGuildsSort;
    order?: SortOrder;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetSponsoredGuildCompetitionsRequest {
    search?: string;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetTopGuildLeadersLeaderboardRequest {
    leaderboardTimeParam: string;
}

export interface GetTopGuildsLeaderboardRequest {
    leaderboardTimeParam: string;
}

export interface JoinGuildRequest {
    id: string;
}

export interface MintGuildBadgeRequest {
    id: string;
}

export interface UpdateGuildRequest {
    id: string;
    addGuildBody?: AddGuildBody;
}

export interface UpdateGuildQuestRequest {
    id: string;
    addGuildQuestBody?: AddGuildQuestBody;
}

export interface UpdateSponsoredGuildCompetitionRequest {
    updateSponsoredCompetitionBody?: UpdateSponsoredCompetitionBody;
}

/**
 * GuildsApi - interface
 * @export
 * @interface GuildsApiInterface
 */
export interface GuildsApiInterface {
    /**
     * Adds a new guild to the system
     * @param {AddGuildBody} [addGuildBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    addGuildRaw(requestParameters: AddGuildRequest): Promise<runtime.ApiResponse<Guild>>;

    /**
     * Adds a new guild to the system
     */
    addGuild(requestParameters: AddGuildRequest): Promise<Guild>;

    /**
     * Should allow a user to claim a guild-specific quest reward.
     * @param {string} id 
     * @param {string} guildId 
     * @param {string} gameId 
     * @param {number} [claimedTarget] 
     * @param {OWRewardType} [rewardType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    claimGuildQuestRaw(requestParameters: ClaimGuildQuestRequest): Promise<runtime.ApiResponse<ClaimGuildQuestResponse>>;

    /**
     * Should allow a user to claim a guild-specific quest reward.
     */
    claimGuildQuest(requestParameters: ClaimGuildQuestRequest): Promise<ClaimGuildQuestResponse>;

    /**
     * Used to create new Guild quest
     * @param {AddGuildQuestBody} [addGuildQuestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    createGuildQuestRaw(requestParameters: CreateGuildQuestRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to create new Guild quest
     */
    createGuildQuest(requestParameters: CreateGuildQuestRequest): Promise<void>;

    /**
     * Used to create new Guild quest
     * @param {CreateSponsoredCompetitionBody} [createSponsoredCompetitionBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    createSponsoredGuildCompetitionRaw(requestParameters: CreateSponsoredGuildCompetitionRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to create new Guild quest
     */
    createSponsoredGuildCompetition(requestParameters: CreateSponsoredGuildCompetitionRequest): Promise<void>;

    /**
     * Gets user\'s guild top 50 members, sorted by total points or monthly points
     * @param {string} leaderboardTimeParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    getCurrentGuildTopMembersRaw(requestParameters: GetCurrentGuildTopMembersRequest): Promise<runtime.ApiResponse<GetCurrentGuildTopMembersResponse>>;

    /**
     * Gets user\'s guild top 50 members, sorted by total points or monthly points
     */
    getCurrentGuildTopMembers(requestParameters: GetCurrentGuildTopMembersRequest): Promise<GetCurrentGuildTopMembersResponse>;

    /**
     * Retrieves Guild data.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    getGuildRaw(requestParameters: GetGuildRequest): Promise<runtime.ApiResponse<Guild>>;

    /**
     * Retrieves Guild data.
     */
    getGuild(requestParameters: GetGuildRequest): Promise<Guild>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    getGuildOwnershipsRaw(): Promise<runtime.ApiResponse<GetGuildOwnershipResponse>>;

    /**
     */
    getGuildOwnerships(): Promise<GetGuildOwnershipResponse>;

    /**
     * Should get the current user\'s progress in a specific guild.
     * @param {string} id 
     * @param {boolean} [freshCopy] If true, bypasses cache and fetches fresh data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    getGuildProgressRaw(requestParameters: GetGuildProgressRequest): Promise<runtime.ApiResponse<GetGuildProgressResponse>>;

    /**
     * Should get the current user\'s progress in a specific guild.
     */
    getGuildProgress(requestParameters: GetGuildProgressRequest): Promise<GetGuildProgressResponse>;

    /**
     * Get list of all event types
     * @param {string} [game] ID of the game to filter quests by
     * @param {string} [guild] 
     * @param {string} [search] 
     * @param {GetQuestActiveStatus} [isActive] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    getGuildQuestsRaw(requestParameters: GetGuildQuestsRequest): Promise<runtime.ApiResponse<GetGuildQuestsResponse>>;

    /**
     * Get list of all event types
     */
    getGuildQuests(requestParameters: GetGuildQuestsRequest): Promise<GetGuildQuestsResponse>;

    /**
     * Should get a list of guilds.
     * @param {Array<string>} [gameIds] 
     * @param {string} [search] 
     * @param {GetGuildsSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    getGuildsRaw(requestParameters: GetGuildsRequest): Promise<runtime.ApiResponse<GetGuildsResponse>>;

    /**
     * Should get a list of guilds.
     */
    getGuilds(requestParameters: GetGuildsRequest): Promise<GetGuildsResponse>;

    /**
     * Get All Sponsored Guild Competitions
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    getSponsoredGuildCompetitionsRaw(requestParameters: GetSponsoredGuildCompetitionsRequest): Promise<runtime.ApiResponse<GetSponsoredGuildCompetitionsResponse>>;

    /**
     * Get All Sponsored Guild Competitions
     */
    getSponsoredGuildCompetitions(requestParameters: GetSponsoredGuildCompetitionsRequest): Promise<GetSponsoredGuildCompetitionsResponse>;

    /**
     * Gets top guild leader rankings, sorted by total points or monthly points
     * @param {string} leaderboardTimeParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    getTopGuildLeadersLeaderboardRaw(requestParameters: GetTopGuildLeadersLeaderboardRequest): Promise<runtime.ApiResponse<GetTopGuildLeadersLeaderboardResponse>>;

    /**
     * Gets top guild leader rankings, sorted by total points or monthly points
     */
    getTopGuildLeadersLeaderboard(requestParameters: GetTopGuildLeadersLeaderboardRequest): Promise<GetTopGuildLeadersLeaderboardResponse>;

    /**
     * Gets top guild rankings, sorted by total points or monthly points
     * @param {string} leaderboardTimeParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    getTopGuildsLeaderboardRaw(requestParameters: GetTopGuildsLeaderboardRequest): Promise<runtime.ApiResponse<GetTopGuildsLeaderboardResponse>>;

    /**
     * Gets top guild rankings, sorted by total points or monthly points
     */
    getTopGuildsLeaderboard(requestParameters: GetTopGuildsLeaderboardRequest): Promise<GetTopGuildsLeaderboardResponse>;

    /**
     * Should allow a user to join a guild.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    joinGuildRaw(requestParameters: JoinGuildRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Should allow a user to join a guild.
     */
    joinGuild(requestParameters: JoinGuildRequest): Promise<void>;

    /**
     * Should allow a user to leave a guild.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    leaveGuildRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     * Should allow a user to leave a guild.
     */
    leaveGuild(): Promise<void>;

    /**
     * Should mint the guild proof of play badge to the user\'s wallet.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    mintGuildBadgeRaw(requestParameters: MintGuildBadgeRequest): Promise<runtime.ApiResponse<NftMintTransaction>>;

    /**
     * Should mint the guild proof of play badge to the user\'s wallet.
     */
    mintGuildBadge(requestParameters: MintGuildBadgeRequest): Promise<NftMintTransaction>;

    /**
     * Updates a guild in the system
     * @param {string} id 
     * @param {AddGuildBody} [addGuildBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    updateGuildRaw(requestParameters: UpdateGuildRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Updates a guild in the system
     */
    updateGuild(requestParameters: UpdateGuildRequest): Promise<void>;

    /**
     * Updates a quest in the system
     * @param {string} id 
     * @param {AddGuildQuestBody} [addGuildQuestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    updateGuildQuestRaw(requestParameters: UpdateGuildQuestRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Updates a quest in the system
     */
    updateGuildQuest(requestParameters: UpdateGuildQuestRequest): Promise<void>;

    /**
     * Used to create new Guild quest
     * @param {UpdateSponsoredCompetitionBody} [updateSponsoredCompetitionBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    updateSponsoredGuildCompetitionRaw(requestParameters: UpdateSponsoredGuildCompetitionRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to create new Guild quest
     */
    updateSponsoredGuildCompetition(requestParameters: UpdateSponsoredGuildCompetitionRequest): Promise<void>;

}

/**
 * no description
 */
export class GuildsApi extends runtime.BaseAPI implements GuildsApiInterface {

    /**
     * Adds a new guild to the system
     */
    async addGuildRaw(requestParameters: AddGuildRequest): Promise<runtime.ApiResponse<Guild>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guilds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddGuildBodyToJSON(requestParameters.addGuildBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GuildFromJSON(jsonValue));
    }

    /**
     * Adds a new guild to the system
     */
    async addGuild(requestParameters: AddGuildRequest): Promise<Guild> {
        const response = await this.addGuildRaw(requestParameters);
        return await response.value();
    }

    /**
     * Should allow a user to claim a guild-specific quest reward.
     */
    async claimGuildQuestRaw(requestParameters: ClaimGuildQuestRequest): Promise<runtime.ApiResponse<ClaimGuildQuestResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling claimGuildQuest.');
        }

        if (requestParameters.guildId === null || requestParameters.guildId === undefined) {
            throw new runtime.RequiredError('guildId','Required parameter requestParameters.guildId was null or undefined when calling claimGuildQuest.');
        }

        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId','Required parameter requestParameters.gameId was null or undefined when calling claimGuildQuest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.guildId !== undefined) {
            queryParameters['guildId'] = requestParameters.guildId;
        }

        if (requestParameters.gameId !== undefined) {
            queryParameters['gameId'] = requestParameters.gameId;
        }

        if (requestParameters.claimedTarget !== undefined) {
            queryParameters['claimedTarget'] = requestParameters.claimedTarget;
        }

        if (requestParameters.rewardType !== undefined) {
            queryParameters['rewardType'] = requestParameters.rewardType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guild-quests/{id}/claim`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClaimGuildQuestResponseFromJSON(jsonValue));
    }

    /**
     * Should allow a user to claim a guild-specific quest reward.
     */
    async claimGuildQuest(requestParameters: ClaimGuildQuestRequest): Promise<ClaimGuildQuestResponse> {
        const response = await this.claimGuildQuestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to create new Guild quest
     */
    async createGuildQuestRaw(requestParameters: CreateGuildQuestRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guild-quests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddGuildQuestBodyToJSON(requestParameters.addGuildQuestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to create new Guild quest
     */
    async createGuildQuest(requestParameters: CreateGuildQuestRequest): Promise<void> {
        await this.createGuildQuestRaw(requestParameters);
    }

    /**
     * Used to create new Guild quest
     */
    async createSponsoredGuildCompetitionRaw(requestParameters: CreateSponsoredGuildCompetitionRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sponsored-guild-competitions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSponsoredCompetitionBodyToJSON(requestParameters.createSponsoredCompetitionBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to create new Guild quest
     */
    async createSponsoredGuildCompetition(requestParameters: CreateSponsoredGuildCompetitionRequest): Promise<void> {
        await this.createSponsoredGuildCompetitionRaw(requestParameters);
    }

    /**
     * Gets user\'s guild top 50 members, sorted by total points or monthly points
     */
    async getCurrentGuildTopMembersRaw(requestParameters: GetCurrentGuildTopMembersRequest): Promise<runtime.ApiResponse<GetCurrentGuildTopMembersResponse>> {
        if (requestParameters.leaderboardTimeParam === null || requestParameters.leaderboardTimeParam === undefined) {
            throw new runtime.RequiredError('leaderboardTimeParam','Required parameter requestParameters.leaderboardTimeParam was null or undefined when calling getCurrentGuildTopMembers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-guild-top-members/{leaderboardTimeParam}`.replace(`{${"leaderboardTimeParam"}}`, encodeURIComponent(String(requestParameters.leaderboardTimeParam))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCurrentGuildTopMembersResponseFromJSON(jsonValue));
    }

    /**
     * Gets user\'s guild top 50 members, sorted by total points or monthly points
     */
    async getCurrentGuildTopMembers(requestParameters: GetCurrentGuildTopMembersRequest): Promise<GetCurrentGuildTopMembersResponse> {
        const response = await this.getCurrentGuildTopMembersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieves Guild data.
     */
    async getGuildRaw(requestParameters: GetGuildRequest): Promise<runtime.ApiResponse<Guild>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getGuild.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/guilds/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GuildFromJSON(jsonValue));
    }

    /**
     * Retrieves Guild data.
     */
    async getGuild(requestParameters: GetGuildRequest): Promise<Guild> {
        const response = await this.getGuildRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getGuildOwnershipsRaw(): Promise<runtime.ApiResponse<GetGuildOwnershipResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Optional", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guilds/ownerships`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGuildOwnershipResponseFromJSON(jsonValue));
    }

    /**
     */
    async getGuildOwnerships(): Promise<GetGuildOwnershipResponse> {
        const response = await this.getGuildOwnershipsRaw();
        return await response.value();
    }

    /**
     * Should get the current user\'s progress in a specific guild.
     */
    async getGuildProgressRaw(requestParameters: GetGuildProgressRequest): Promise<runtime.ApiResponse<GetGuildProgressResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getGuildProgress.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.freshCopy !== undefined) {
            queryParameters['freshCopy'] = requestParameters.freshCopy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Optional", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guilds/{id}/progress`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGuildProgressResponseFromJSON(jsonValue));
    }

    /**
     * Should get the current user\'s progress in a specific guild.
     */
    async getGuildProgress(requestParameters: GetGuildProgressRequest): Promise<GetGuildProgressResponse> {
        const response = await this.getGuildProgressRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get list of all event types
     */
    async getGuildQuestsRaw(requestParameters: GetGuildQuestsRequest): Promise<runtime.ApiResponse<GetGuildQuestsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.game !== undefined) {
            queryParameters['game'] = requestParameters.game;
        }

        if (requestParameters.guild !== undefined) {
            queryParameters['guild'] = requestParameters.guild;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['isActive'] = requestParameters.isActive;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guild-quests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGuildQuestsResponseFromJSON(jsonValue));
    }

    /**
     * Get list of all event types
     */
    async getGuildQuests(requestParameters: GetGuildQuestsRequest): Promise<GetGuildQuestsResponse> {
        const response = await this.getGuildQuestsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Should get a list of guilds.
     */
    async getGuildsRaw(requestParameters: GetGuildsRequest): Promise<runtime.ApiResponse<GetGuildsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.gameIds) {
            queryParameters['gameIds'] = requestParameters.gameIds;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/guilds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGuildsResponseFromJSON(jsonValue));
    }

    /**
     * Should get a list of guilds.
     */
    async getGuilds(requestParameters: GetGuildsRequest): Promise<GetGuildsResponse> {
        const response = await this.getGuildsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get All Sponsored Guild Competitions
     */
    async getSponsoredGuildCompetitionsRaw(requestParameters: GetSponsoredGuildCompetitionsRequest): Promise<runtime.ApiResponse<GetSponsoredGuildCompetitionsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sponsored-guild-competitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSponsoredGuildCompetitionsResponseFromJSON(jsonValue));
    }

    /**
     * Get All Sponsored Guild Competitions
     */
    async getSponsoredGuildCompetitions(requestParameters: GetSponsoredGuildCompetitionsRequest): Promise<GetSponsoredGuildCompetitionsResponse> {
        const response = await this.getSponsoredGuildCompetitionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets top guild leader rankings, sorted by total points or monthly points
     */
    async getTopGuildLeadersLeaderboardRaw(requestParameters: GetTopGuildLeadersLeaderboardRequest): Promise<runtime.ApiResponse<GetTopGuildLeadersLeaderboardResponse>> {
        if (requestParameters.leaderboardTimeParam === null || requestParameters.leaderboardTimeParam === undefined) {
            throw new runtime.RequiredError('leaderboardTimeParam','Required parameter requestParameters.leaderboardTimeParam was null or undefined when calling getTopGuildLeadersLeaderboard.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/top-guild-leaders/{leaderboardTimeParam}`.replace(`{${"leaderboardTimeParam"}}`, encodeURIComponent(String(requestParameters.leaderboardTimeParam))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopGuildLeadersLeaderboardResponseFromJSON(jsonValue));
    }

    /**
     * Gets top guild leader rankings, sorted by total points or monthly points
     */
    async getTopGuildLeadersLeaderboard(requestParameters: GetTopGuildLeadersLeaderboardRequest): Promise<GetTopGuildLeadersLeaderboardResponse> {
        const response = await this.getTopGuildLeadersLeaderboardRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets top guild rankings, sorted by total points or monthly points
     */
    async getTopGuildsLeaderboardRaw(requestParameters: GetTopGuildsLeaderboardRequest): Promise<runtime.ApiResponse<GetTopGuildsLeaderboardResponse>> {
        if (requestParameters.leaderboardTimeParam === null || requestParameters.leaderboardTimeParam === undefined) {
            throw new runtime.RequiredError('leaderboardTimeParam','Required parameter requestParameters.leaderboardTimeParam was null or undefined when calling getTopGuildsLeaderboard.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/top-guilds/{leaderboardTimeParam}`.replace(`{${"leaderboardTimeParam"}}`, encodeURIComponent(String(requestParameters.leaderboardTimeParam))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopGuildsLeaderboardResponseFromJSON(jsonValue));
    }

    /**
     * Gets top guild rankings, sorted by total points or monthly points
     */
    async getTopGuildsLeaderboard(requestParameters: GetTopGuildsLeaderboardRequest): Promise<GetTopGuildsLeaderboardResponse> {
        const response = await this.getTopGuildsLeaderboardRaw(requestParameters);
        return await response.value();
    }

    /**
     * Should allow a user to join a guild.
     */
    async joinGuildRaw(requestParameters: JoinGuildRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling joinGuild.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guilds/{id}/join`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Should allow a user to join a guild.
     */
    async joinGuild(requestParameters: JoinGuildRequest): Promise<void> {
        await this.joinGuildRaw(requestParameters);
    }

    /**
     * Should allow a user to leave a guild.
     */
    async leaveGuildRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guilds/leave`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Should allow a user to leave a guild.
     */
    async leaveGuild(): Promise<void> {
        await this.leaveGuildRaw();
    }

    /**
     * Should mint the guild proof of play badge to the user\'s wallet.
     */
    async mintGuildBadgeRaw(requestParameters: MintGuildBadgeRequest): Promise<runtime.ApiResponse<NftMintTransaction>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mintGuildBadge.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guilds/{id}/mint`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NftMintTransactionFromJSON(jsonValue));
    }

    /**
     * Should mint the guild proof of play badge to the user\'s wallet.
     */
    async mintGuildBadge(requestParameters: MintGuildBadgeRequest): Promise<NftMintTransaction> {
        const response = await this.mintGuildBadgeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a guild in the system
     */
    async updateGuildRaw(requestParameters: UpdateGuildRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateGuild.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guilds/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddGuildBodyToJSON(requestParameters.addGuildBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a guild in the system
     */
    async updateGuild(requestParameters: UpdateGuildRequest): Promise<void> {
        await this.updateGuildRaw(requestParameters);
    }

    /**
     * Updates a quest in the system
     */
    async updateGuildQuestRaw(requestParameters: UpdateGuildQuestRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateGuildQuest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guild-quests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddGuildQuestBodyToJSON(requestParameters.addGuildQuestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a quest in the system
     */
    async updateGuildQuest(requestParameters: UpdateGuildQuestRequest): Promise<void> {
        await this.updateGuildQuestRaw(requestParameters);
    }

    /**
     * Used to create new Guild quest
     */
    async updateSponsoredGuildCompetitionRaw(requestParameters: UpdateSponsoredGuildCompetitionRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sponsored-guild-competitions`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSponsoredCompetitionBodyToJSON(requestParameters.updateSponsoredCompetitionBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to create new Guild quest
     */
    async updateSponsoredGuildCompetition(requestParameters: UpdateSponsoredGuildCompetitionRequest): Promise<void> {
        await this.updateSponsoredGuildCompetitionRaw(requestParameters);
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Criteria for winning a sponsored competition
 * @export
 * @enum {string}
 */
export enum WinningCriteria {
    TOPGUILDXPGAINER = 'TOP_GUILD_XP_GAINER',
    TOPOVERALLXPGAINER = 'TOP_OVERALL_XP_GAINER',
    WINNINGGUILD = 'WINNING_GUILD'
}

export function WinningCriteriaFromJSON(json: any): WinningCriteria {
    return WinningCriteriaFromJSONTyped(json, false);
}

export function WinningCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): WinningCriteria {
    return json as WinningCriteria;
}

export function WinningCriteriaToJSON(value?: WinningCriteria | null): any {
    return value as any;
}


/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetSponsoredGuildCompetitionsResponseAllOf,
    GetSponsoredGuildCompetitionsResponseAllOfFromJSON,
    GetSponsoredGuildCompetitionsResponseAllOfFromJSONTyped,
    GetSponsoredGuildCompetitionsResponseAllOfToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
    SponsoredGuildCompetition,
    SponsoredGuildCompetitionFromJSON,
    SponsoredGuildCompetitionFromJSONTyped,
    SponsoredGuildCompetitionToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSponsoredGuildCompetitionsResponse
 */
export interface GetSponsoredGuildCompetitionsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<SponsoredGuildCompetition>}
     * @memberof GetSponsoredGuildCompetitionsResponse
     */
    sponsoredGuildCompetitions: Array<SponsoredGuildCompetition>;
}

export function GetSponsoredGuildCompetitionsResponseFromJSON(json: any): GetSponsoredGuildCompetitionsResponse {
    return GetSponsoredGuildCompetitionsResponseFromJSONTyped(json, false);
}

export function GetSponsoredGuildCompetitionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSponsoredGuildCompetitionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'sponsoredGuildCompetitions': ((json['sponsoredGuildCompetitions'] as Array<any>).map(SponsoredGuildCompetitionFromJSON)),
    };
}

export function GetSponsoredGuildCompetitionsResponseToJSON(value?: GetSponsoredGuildCompetitionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'sponsoredGuildCompetitions': ((value.sponsoredGuildCompetitions as Array<any>).map(SponsoredGuildCompetitionToJSON)),
    };
}



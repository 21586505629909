/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SponsoredRewardEmail,
    SponsoredRewardEmailFromJSON,
    SponsoredRewardEmailFromJSONTyped,
    SponsoredRewardEmailToJSON,
    SponsoredRewardType,
    SponsoredRewardTypeFromJSON,
    SponsoredRewardTypeFromJSONTyped,
    SponsoredRewardTypeToJSON,
    WinningCriteria,
    WinningCriteriaFromJSON,
    WinningCriteriaFromJSONTyped,
    WinningCriteriaToJSON,
} from './';

/**
 * Collection contains the user\'s reward stats/progress.
 * @export
 * @interface SponsoredReward
 */
export interface SponsoredReward {
    /**
     * 
     * @type {WinningCriteria}
     * @memberof SponsoredReward
     */
    winningCriteria: WinningCriteria;
    /**
     * 
     * @type {string}
     * @memberof SponsoredReward
     */
    description: string;
    /**
     * 
     * @type {SponsoredRewardType}
     * @memberof SponsoredReward
     */
    rewardType: SponsoredRewardType;
    /**
     * 
     * @type {number}
     * @memberof SponsoredReward
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof SponsoredReward
     */
    nftTrackerId?: string;
    /**
     * 
     * @type {SponsoredRewardEmail}
     * @memberof SponsoredReward
     */
    email: SponsoredRewardEmail;
}

export function SponsoredRewardFromJSON(json: any): SponsoredReward {
    return SponsoredRewardFromJSONTyped(json, false);
}

export function SponsoredRewardFromJSONTyped(json: any, ignoreDiscriminator: boolean): SponsoredReward {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'winningCriteria': WinningCriteriaFromJSON(json['winningCriteria']),
        'description': json['description'],
        'rewardType': SponsoredRewardTypeFromJSON(json['rewardType']),
        'amount': json['amount'],
        'nftTrackerId': !exists(json, 'nftTrackerId') ? undefined : json['nftTrackerId'],
        'email': SponsoredRewardEmailFromJSON(json['email']),
    };
}

export function SponsoredRewardToJSON(value?: SponsoredReward | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'winningCriteria': WinningCriteriaToJSON(value.winningCriteria),
        'description': value.description,
        'rewardType': SponsoredRewardTypeToJSON(value.rewardType),
        'amount': value.amount,
        'nftTrackerId': value.nftTrackerId,
        'email': SponsoredRewardEmailToJSON(value.email),
    };
}



/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Email to send to winners of a sponsored competition
 * @export
 * @interface SponsoredRewardEmail
 */
export interface SponsoredRewardEmail {
    /**
     * Subject of the email to send to winners
     * @type {string}
     * @memberof SponsoredRewardEmail
     */
    subject: string;
    /**
     * Body of the email to send to winners
     * @type {string}
     * @memberof SponsoredRewardEmail
     */
    body: string;
}

export function SponsoredRewardEmailFromJSON(json: any): SponsoredRewardEmail {
    return SponsoredRewardEmailFromJSONTyped(json, false);
}

export function SponsoredRewardEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): SponsoredRewardEmail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subject': json['subject'],
        'body': json['body'],
    };
}

export function SponsoredRewardEmailToJSON(value?: SponsoredRewardEmail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subject': value.subject,
        'body': value.body,
    };
}



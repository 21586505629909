/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of reward that can be won in a sponsored competition
 * @export
 * @enum {string}
 */
export enum SponsoredRewardType {
    XP = 'XP',
    NFT = 'NFT',
    FUEL = 'FUEL',
    GIFTCARD = 'GIFT_CARD',
    OTHER = 'OTHER'
}

export function SponsoredRewardTypeFromJSON(json: any): SponsoredRewardType {
    return SponsoredRewardTypeFromJSONTyped(json, false);
}

export function SponsoredRewardTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SponsoredRewardType {
    return json as SponsoredRewardType;
}

export function SponsoredRewardTypeToJSON(value?: SponsoredRewardType | null): any {
    return value as any;
}

